
function Ten4Slideshow( container ) {
    this.list_items = container.querySelectorAll( '.slideshow__list__item' );
	this.slides = container.querySelectorAll( '.slideshow__slide' );
	this.top_slide_i = 0;
	this.slide_callbacks = [];
	this.slides[this.top_slide_i].classList.add( 'slideshow__slide--top' );
	this.addSlideProp( container );
	const next = document.getElementById( container.getAttribute( 'data-next' ) );
	if ( next ) {
		next.addEventListener( 'click', ( function( slideshow ) {
			return function() {
				container.classList.add( 'slideshow--swap' );
				slideshow.slide();
			};
		} )( this ) );
	}
	const previous = document.getElementById( container.getAttribute( 'data-previous' ) );
	if ( previous ) {
		previous.addEventListener( 'click', ( function( slideshow ) {
			return function() {
				container.classList.remove( 'slideshow--swap' );
				slideshow.slide( true );
			};
		} )( this ) );
	}
    this.list_items[0].classList.add( 'slideshow__list__item--selected' );
}

Ten4Slideshow.prototype.slideTo = function( index ) {
	if ( index < 0 || index > ( this.slides.length - 1 ) ) {
		return;
	}
	for ( let i = 0; i < this.slides.length; i += 1 ) {
		this.slides[i].classList.remove( 'slideshow__slide--behind' );
	}
	for ( let j = 0; j < this.slide_callbacks.length; j += 1 ) {
		this.slide_callbacks[j]( index );
	}
	// This fixes an issue with classes being applied simultaneously - using new
	// frames fixes it.
	window.requestAnimationFrame( ( ( slideshow ) => {
		return function() {
			slideshow.slides[slideshow.top_slide_i].classList.remove( 'slideshow__slide--top' );
			slideshow.slides[slideshow.top_slide_i].classList.add( 'slideshow__slide--behind' );
			window.requestAnimationFrame( () => {
				slideshow.slides[index].classList.add( 'slideshow__slide--top' );
				slideshow.list_items[index].classList.add( 'slideshow__list__item--selected' );
				slideshow.top_slide_i = index;
			} );
		};
	} )( this ) );
};

Ten4Slideshow.prototype.slide = function( backwards ) {
	let next_slide_i = this.top_slide_i + ( backwards ? -1 : 1 );
    if ( next_slide_i < 0 ) {
		next_slide_i = this.slides.length - 1;
	} else if ( next_slide_i > ( this.slides.length - 1 ) ) {
        next_slide_i = 0;
	} 
    this.list_items[this.top_slide_i].classList.remove( 'slideshow__list__item--selected' );
    this.slideTo( next_slide_i );
};

Ten4Slideshow.prototype.addSlideProp = function( container ) {
	const slideshow_prop = this.getTallestSlide().cloneNode( true );
	slideshow_prop.classList.remove( 'slideshow__slide' );
	slideshow_prop.classList.add( 'slideshow__prop' );
	container.appendChild( slideshow_prop );
};

Ten4Slideshow.prototype.getTallestSlide = function() {
	let tallest = this.slides[0];
	for ( let i = 1; i < this.slides.length; i += 1 ) {
		if ( this.slides[i].clientHeight > tallest.clientHeight ) {
			tallest = this.slides[i];
		}
	}
	return tallest;
};

Ten4Slideshow.prototype.addCallback = function( func ) {
	this.slide_callbacks.push( func );
};

export default Ten4Slideshow;