import { breakpoints } from '../variables.yml';

import 'focus-visible';
import { getWindowWidth, throttle } from './helpers.js';
import EmblaCarousel from 'embla-carousel';
import { setupPrevNextBtns, disablePrevNextBtns } from './vendor/prevAndNextButtons.js';
export * from './CookieConsentManager.js';
import Ten4Slideshow from './vendor/slideshow.js';
import './vendor/ajax.min.js';
import { LoadOMatic } from './vendor/load-o-matic.js';
import barba from '@barba/core';
import expandImage from './transitions/expandImage';
import fadeOutImage from './transitions/fadeOutImage';

let prev_breakpoint_mobile = false;

runMainFunctions();
entryTransition();

function getMobileBreakpoint() {
	if ( window.location.href.includes( 'ie' ) ) {
		return breakpoints.c.width ;
	} else {
		return breakpoints.e.width ;
	}
}

function emblaCarousel() { // Embla General swiping & Gallery Lightbox
	const embla_node = document.querySelectorAll( '.embla' );

	if ( !embla_node.length > 0 ) {
		return;
	}

	emblaGallery( embla_node );

	window.addEventListener( 'load', () => {
		emblaGallery( embla_node );
	} );

	window.addEventListener( 'resize', throttle( () => {
		emblaGallery( embla_node );
	} ), 50 );

	function emblaGallery( embla_node ) {
		for ( let i = 0; i < embla_node.length; i++ ) {
			const viewport_node = embla_node[i].querySelector( '.embla__viewport' );
			const prev_btn = embla_node[i].querySelector( '.embla__button--prev' );
			const next_btn = embla_node[i].querySelector( '.embla__button--next' );

			if ( viewport_node.querySelectorAll( '.embla__slide' ).length > 1 ) {
				const embla = EmblaCarousel( viewport_node, { align: 'start', containScroll: 'keepSnaps', loop: false } );
				embla_node[i].ten4_embla = embla;
				if ( prev_btn && next_btn ) {
					const disable_prev_and_next_btns = disablePrevNextBtns( prev_btn, next_btn, embla );
					setupPrevNextBtns( prev_btn, next_btn, embla );

					embla.on( 'select', disable_prev_and_next_btns );
					embla.on( 'init', disable_prev_and_next_btns );
					embla.on( 'reInit', disable_prev_and_next_btns );
				}

				// Lightbox
				if ( embla_node[i].closest( '.lightbox' ) ) {
					enableLightbox( embla_node[i], embla );
				}
			}
		}
	}

	function enableLightbox( gallery, embla_gallery ) {
		const gallery_images = gallery.getElementsByClassName( 'embla__slide' );
		const lightbox = gallery.closest( '.lightbox' );
		for ( let i = 0; i < gallery_images.length; i++ ) {
			gallery_images[i].addEventListener( 'click', () => {
				document.body.classList.add( 'lightbox-active' );
				lightbox.classList.add( 'lightbox--active' );
				if ( embla_gallery.slidesNotInView().length < 1 && i !== 0 ) {
					// When there are not enough images that the carousel doesn't overflow,
					// no "scrolling" to that clicked image is happening until the lightbox is fully loaded
					gallery_images[0].style.opacity = 0; //Hide the first image as it is the default selected snap when lightbox first opens
					setTimeout( ()=>{
						embla_gallery.scrollTo( i, true );
						gallery_images[0].style.opacity = 1;
					}, 50 );
				} else {
					embla_gallery.scrollTo( i, true );
				}
			} );
		}
		const close_button = lightbox.querySelector( '.lightbox__close' );
		close_button.addEventListener( 'click', () => {
			document.body.classList.remove( 'lightbox-active' );
			lightbox.classList.remove( 'lightbox--active' );
			embla_gallery.scrollTo( embla_gallery.selectedScrollSnap(), true );
		} );
	}
}

function partnersResponsiveCarousel() { // Partners mobile and desktop carousel layout
	const partner_carousels = document.querySelectorAll( '.embla--partners' );
	if ( !partner_carousels ) {
		return;
	}
	window.addEventListener( 'resize', () => {
		reOrderCarouselLayout();
	} );
	function reOrderCarouselLayout() {
		for ( let i = 0; i < partner_carousels.length; i++ ) {
			const viewport = partner_carousels[i].querySelector( '.embla__viewport' );
			const carousel_desktop = partner_carousels[i].querySelector( '.embla__container--desktop' );
			const carousel_mobile = partner_carousels[i].querySelector( '.embla__container--mobile' );
			if ( getWindowWidth() <= getMobileBreakpoint() ) {
				viewport.insertBefore( carousel_mobile, carousel_desktop );
			} else {
				viewport.insertBefore( carousel_desktop, carousel_mobile );
			}
		}
	}
	reOrderCarouselLayout();
}

function mobileHeader() { // Mobile header
	const header_menu = document.querySelector( '.header__menu' );

	if ( !header_menu ) {
		return;
	}
	const nav_content = document.querySelector( '.header__nav__content' );
	const nav_transition_shape = document.querySelector( '.header__shape' );

	header_menu.addEventListener( 'click', ( ) => {
		if ( document.body.classList.contains( 'nav-open' ) ) {
			header_menu.setAttribute( 'aria-label', 'Open navigation' );
			nav_content.classList.remove( 'header__nav__content--opening' );
			nav_transition_shape.style.transition = 'right 0.3s, top 0.3s, height 0.3s, opacity 1s, width 0.3s, border-radius 0.3s';
			setTimeout( ()=> {
				document.body.classList.remove( 'nav-open' );
			}, 200 );
		} else {
			nav_transition_shape.style.transition = 'right 1s, top 1s, height 1s, opacity 1s, width 1s, border-radius 1s';
			document.body.classList.add( 'nav-open' );
			header_menu.setAttribute( 'aria-label', 'Close navigation' );
			setTimeout( ()=> {
				nav_content.classList.add( 'header__nav__content--opening' );
			}, 200 );
		}
	} );

	const mobile_nav_breakpoint = getMobileBreakpoint();

	window.addEventListener( 'resize', () => {
		if ( document.body.classList.contains( 'nav-open' ) && getWindowWidth() > mobile_nav_breakpoint ) {
			document.body.classList.remove( 'nav-open' );
		}
	} );
}

function portfolioEntryToggle() { // Portfolio entry details toggle
	const details_toggle = document.querySelector( '.intro__details__toggle' );

	if ( !details_toggle ) {
		return;
	}
	const details = document.querySelector( '.intro__details' );
	details_toggle.addEventListener( 'click', ( ) => {
		if ( details_toggle.classList.contains( 'intro__details__toggle--hide' ) ) {
			details_toggle.classList.remove( 'intro__details__toggle--hide' );
			details_toggle.setAttribute( 'aria-label', 'Show key information' );
			details_toggle.textContent = 'Show key information';
			details.classList.remove( 'intro__details--visible' );
		} else {
			details_toggle.classList.add( 'intro__details__toggle--hide' );
			details_toggle.setAttribute( 'aria-label', 'Hide key information' );
			details_toggle.textContent = 'Hide key information';
			details.classList.add( 'intro__details--visible' );
		}
	} );

}

function toggles() { // Data toggles
	function doToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		for ( let i = 0; i < togglees.length; i += 1 ) {
			togglees[i].classList.toggle( 'toggled', !togglees[i].classList.contains( 'toggled' ) );
		}
		let togglers = [];
		if ( toggler.getAttribute( 'data-togglers' ) ) {
			togglers = Array.from( document.querySelectorAll( toggler.getAttribute( 'data-togglers' ) ) );
		}
		togglers.unshift( toggler );
		for ( let i = 0; i < togglers.length; i += 1 ) {
			togglers[i].classList.toggle( 'toggler--toggled', !togglers[i].classList.contains( 'toggler--toggled' ) );
		}

		if ( toggler.classList.contains( 'toggler--toggled' ) ) {
			if ( toggler.getAttribute( 'data-focus-element' ) ) {
				document.querySelector( toggler.getAttribute( 'data-focus-element' ) ).focus();
			}
			if ( toggler.getAttribute( 'data-toggled-text' ) ) {
				toggler.innerHTML = toggler.getAttribute( 'data-toggled-text' );
			}
		} else if ( toggler.getAttribute( 'data-untoggled-text' ) ) {
			toggler.innerHTML = toggler.getAttribute( 'data-untoggled-text' );
		}
	}

	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
}

function videoEmbeds() { // Video embeds
	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		container.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	function handleVideoClick( container, play_button, video_container ) {
		return ( event ) => {
			event.preventDefault();
			buildVideoPlayer( container );
			play_button.style.display = 'none';
			video_container.classList.add( 'content-block__video--remove-tint' );
		};
	}

	function initiateEventListeners() {
		window.addEventListener( 'load', () => {
			const videos = document.querySelectorAll( '.video-embed' );
			if ( !videos ) {
				return;
			}
			for ( let j = 0; j < videos.length; j += 1 ) {
				const play_button = videos[j].querySelector( '.content-block__video__button' );
				const video_container = videos[j].parentNode;
				videos[j].addEventListener( 'click', handleVideoClick( videos[j], play_button, video_container ) );
			}
		} );
	}

	initiateEventListeners();
	window.addEventListener( 'load', initiateEventListeners( ) );
}

function listSlideshow() {  // Full Height Slideshows (list)
	function makeSlideshow( element ) {
		const slideshow = new Ten4Slideshow( element );
		const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
		window.setInterval( ()=> {
			slideshow.slide();
		}, slide_interval );

		for ( let j = 0; j < slideshow.list_items.length; j++ ) {
			const list_item_id = parseInt( slideshow.list_items[j].id );
			slideshow.list_items[j].addEventListener( 'mouseover', () => {
				slideshow.list_items[slideshow.top_slide_i].classList.remove( 'slideshow__list__item--selected' );
				slideshow.slideTo( list_item_id );
			} );
			slideshow.list_items[j].addEventListener( 'mouseout', () => {
				if ( slideshow.top_slide_i !== list_item_id ) {
					slideshow.list_items[slideshow.top_slide_i].classList.remove( 'slideshow__list__item--selected' );
				}
			} );
		}
	}
	const slideshows = document.querySelectorAll( '.slideshow' );
	for ( let i = 0; i < slideshows.length; i += 1 ) {
		makeSlideshow( slideshows[i] );
	}
}

function filterToggles() { // Filter toggles mobile
	const filter_container = document.querySelector( '.filter' );
	if ( !filter_container  ) {
		return;
	}
	const selected_input = filter_container.querySelector( '.filter__input:checked' );
	const filter_dropdown = filter_container.querySelector( '.filter__dropdown' );
	if ( getWindowWidth() > getMobileBreakpoint() ) {
		if ( filter_container.classList.contains( 'filter--toggled' ) ) {
			filter_container.classList.remove( 'filter--toggled' );
		}
		showHiddenFilter();
	} else {
		handleSelectedFilter( selected_input );

		filter_container.addEventListener( 'input', ( e ) => {
			handleSelectedFilter( e.target );
		} );
	}

	function handleSelectedFilter( selected_input ) {
		showHiddenFilter();
		const corresponding_label = filter_container.querySelector( `label[for="${selected_input.id}"]` );
		filter_dropdown.textContent = corresponding_label.textContent;
		corresponding_label.classList.add( 'filter__label--hidden' );
		filter_container.classList.remove( 'filter--toggled' );
	}

	function showHiddenFilter() {
		const prev_selected = filter_container.querySelector( '.filter__label--hidden' );
		if ( prev_selected ) {
			prev_selected.classList.remove( 'filter__label--hidden' );
		}
	}
}

let loadomatic;
// eslint-disable-next-line snakecasejs/snakecasejs
const buildLoadOMatic = ( options ) => {
	const container = document.querySelector( '.loadomatic' );
	if ( !container ) {
		return;
	}
	loadomatic = new LoadOMatic( options );
	const observer = new IntersectionObserver( ( items ) => {
		for ( let i = 0; i < items.length; i++ ) {
			if ( items[i].isIntersecting ) {
				loadomatic.loadMore();
			}
		}
	}, {
		rootMargin: '-50px 0px -50px 0px',
	} );
	observer.observe(
		document.querySelector( '.loadomatic__elements' )
	);
};

function filters() { // Filters
	const filters_form = document.getElementById( 'js-listing-filters' );
	const results_container = document.querySelector( '.loadomatic' );

	if ( !filters_form || !results_container ) {
		return;
	}

	const segments = window.location.pathname.split( '/' );
	const pagination_message = document.getElementById( 'js-pagination-message' );
	const checkboxes = filters_form.querySelectorAll( 'input[type=radio]' );

	for ( let i = 0; i < checkboxes.length; i++ ) {
		checkboxes[ i ].addEventListener( 'change', ( e ) => {
			const checkbox_name = e.target.name;
			const checkbox_checked = e.target.checked;
			const checkbox_value = e.target.value;
			const checkboxes = filters_form.querySelectorAll( 'input[name=\'' + checkbox_name + '\']' );

			if ( checkbox_value === 'all' && checkbox_checked ) {
				for ( let j = 0; j < checkboxes.length; j++ ) {
					if ( checkboxes[ j ].value !== 'all' && checkboxes[ j ].checked ) {
						checkboxes[ j ].checked = false;
					}
				}
			} else if ( checkbox_checked ) {
				for ( let j = 0; j < checkboxes.length; j++ ) {
					if ( checkboxes[ j ].value === 'all' && checkboxes[ j ].checked ) {
						checkboxes[ j ].checked = false;
					}
				}
			} else {
				const checkboxes_checked = filters_form.querySelectorAll( 'input[name=\'' + checkbox_name + '\']:checked' );

				if ( checkboxes_checked.length === 0 ) {
					for ( let j = 0; j < checkboxes.length; j++ ) {
						if ( checkboxes[ j ].value === 'all' ) {
							checkboxes[ j ].checked = true;
						}
					}
				}
			}
		} );
	}

	filters_form.addEventListener( 'submit', ( e ) => {
		e.preventDefault();
		handleFormChange();
	} );

	filters_form.addEventListener( 'change', () => {
		handleFormChange();
	} );

	function getUrlFromForm( form, clear ) {
		let first_segment = segments[1];
		const second_segment = segments[2];
		if ( second_segment === 'team' || second_segment === 'portfolio' ) {
			first_segment = second_segment;
		}
		const search_url = `${first_segment}${ clear ? '' : '?' + new URLSearchParams( new FormData( form ) ).toString() }`;
		return search_url;
	}

	function handleFormChange( clear = false ) {
		const search_url = getUrlFromForm( filters_form, clear );
		if ( pagination_message ) {
			pagination_message.classList.add( 'removed' );
		}
		window.history.pushState( null, null, search_url );
		loadomatic.resetEnd();
		loadomatic.loadMore( true, false );
	}
}

window.runPageScript = () => {
	if ( typeof window.hollybrook === 'function' ) {
		window.hollybrook( {
			buildLoadOMatic: buildLoadOMatic,
		} );
	}
};

window.runPageScript();

function hoverSwipeMotion() { // Hover swipe motion
	const containers = document.querySelectorAll( '.hover__container' );
	if ( !containers ) {
		return;
	}

	containers.forEach( container => {
		const slider = container.querySelector( '.hover__slider' );
		if ( container.classList.contains( 'filter__inner' ) ) {
			const selected_item_id = container.querySelector( 'input[type="radio"]:checked' ).id;
			let selected_item = container.querySelector( `label[for="${selected_item_id}"]` );
			initiateEventListeners( container, slider, selected_item );
			if ( getWindowWidth() > getMobileBreakpoint() ) {
				setSelectedStyles( container, slider, selected_item );
			}
			const filters = container.querySelectorAll( '.filter__label' );
			filters.forEach( filter => {
				filter.addEventListener( 'click', () => {
					selected_item = filter;
					selected_item.style.border = 'none';
					initiateEventListeners( container, slider, selected_item );
				} );
			} );
		} else {
			const selected_item = document.querySelector( '.header__nav__item--selected' );
			initiateEventListeners( container, slider, selected_item );
		}
	} );

	function initiateEventListeners( container, slider, selected_item ) {
		const url_segments = window.location.href.split( '/' );
		const last_slug = url_segments[url_segments.length - 1];
		container.addEventListener( 'mouseout', () => {
			if ( getWindowWidth() > getMobileBreakpoint() && last_slug !== 'contact' ) {
				returnToOriginalPosition( slider, selected_item, container );
			}
		} );
		container.addEventListener( 'mouseover', ( e ) => {
			if ( getWindowWidth() > getMobileBreakpoint()  && last_slug !== 'contact'  ) {
				if ( e.target.getAttribute( 'class' ) ) {
					return;
				}
				transitionSlider( slider, selected_item, e.target, container );
			}
		} );
	}

	function transitionSlider(  slider, selected_item, target, container ) {
		const item_bounds = target.getBoundingClientRect();
		selected_item.style.backgroundColor = 'transparent';
		selected_item.style.border = 'none';
		if ( !container.classList.contains( 'filter__inner' ) && navColoursAreInverted( selected_item ) ) {
			selected_item.querySelector( 'a' ).style.color = 'rgb(10, 29, 42)';
		}
		slider.style.opacity = 1;
		slider.style.top = item_bounds.y - container.getBoundingClientRect().y - 8 + 'px';
		slider.style.left = item_bounds.x - container.getBoundingClientRect().x - 16 + 'px';
		slider.style.height = item_bounds.height + 'px';
		slider.style.width = item_bounds.width + 'px';
		slider.style.transition = 'left 0.6s ease, width 0.6s ease';
		if (  !container.classList.contains( 'filter__inner' ) && navColoursAreInverted( selected_item ) && target.style.color !== 'white' ) {
			target.style.transition = 'color 0.6s ease';
			target.style.color = 'white';
		}
	}

	function returnToOriginalPosition( slider, selected_item, container ) {
		if ( !selected_item ) {
			return;
		}
		const selected_item_bounds = selected_item.getBoundingClientRect();
		const selected_item_left = selected_item_bounds.x - container.getBoundingClientRect().x;
		slider.style.left = selected_item_left + 'px';
		slider.style.width = selected_item_bounds.width - 32 + 'px';

		const nav_items = document.querySelectorAll( '.header__nav__item a' );
		if ( navColoursAreInverted( selected_item ) ) {
			nav_items.forEach( item => {
				item.style.color = 'rgb(10, 29, 42)';
			} );
			selected_item.querySelector( 'a' ).style.transition = 'color 0.6s ease';
			selected_item.querySelector( 'a' ).style.color = 'white';
		}
	}

	function setSelectedStyles( container, slider, selected_item )  {
		const all_items = container.querySelectorAll( '.filter__label' );
		all_items.forEach( item => {
			item.style.border = 'none';
			slider.style.opacity = 0;
		} );
		selected_item.style.border = '1px solid white';
	}

	function navColoursAreInverted( selected_item ) {
		if ( window.getComputedStyle( selected_item.nextElementSibling ).getPropertyValue( 'color' ) === 'rgb(10, 29, 42)' ) {
			return true;
		}
		return false;
	}
}

window.addEventListener( 'load', () => {
	hoverSwipeMotion();
} );

window.addEventListener( 'resize', () => {
	filterToggles();
	const filter_slider = document.querySelector( '.hover__slider--filter' );
	const nav_slider = document.querySelector( '.hover__slider--nav' );

	if ( getWindowWidth() > getMobileBreakpoint() && prev_breakpoint_mobile ) {
		if ( filter_slider ) {
			filter_slider.style.border = '1px solid white';
		}
		nav_slider.style.backgroundColor = '#0A1D2A';
		hoverSwipeMotion();
		prev_breakpoint_mobile = false;
	} else if ( getWindowWidth() <= getMobileBreakpoint() && !prev_breakpoint_mobile ) {
		if ( filter_slider ) {
			filter_slider.style.border = 'none';
		}
		nav_slider.style.backgroundColor = 'transparent';
		const selected_item = document.querySelector( '.header__nav__item--selected' );
		selected_item.style.backgroundColor = '#0A1D2A';
		selected_item.style.color = 'white';
	}

	if ( getWindowWidth() > getMobileBreakpoint() ) {
		prev_breakpoint_mobile = false;
	} else {
		prev_breakpoint_mobile = true;
	}
} );


window.addEventListener( 'click', ( e ) => {
	const filter = document.querySelector( '.filter' );
	if ( filter && filter.classList.contains( 'filter--toggled' ) && !( e.target.classList.contains( 'filter__dropdown' )  ) ) {
		filter.classList.remove( 'filter--toggled' );
	} else if ( e.target.classList.contains( 'filter__dropdown' ) ) {
		filter.classList.toggle( 'filter--toggled' );
	}
} );

function runMainFunctions() {
	emblaCarousel();
	mobileHeader();
	portfolioEntryToggle();
	partnersResponsiveCarousel();
	videoEmbeds();
	filterToggles();
	toggles();
	listSlideshow();
	filters();
	hoverSwipeMotion();
}

function entryTransition() { // Barba listing: Listing to Entry transitions
	const barba_wrapper = document.querySelector( '[data-barba="wrapper"]' );
	if ( barba_wrapper ) {
		const duration = 800;
		const intro_fade_duration = 800;
		barba.init( {
			debug: false,
			transitions: [
				{
					name: 'portfolio-transition',
					from: { namespace: [ 'homepage', 'portfolio', 'projects', 'approach', 'approaches', 'about_us', 'core_teams', 'team', 'team_members' ] },
					to: { namespace: [ 'projects', 'approaches', 'core_teams', 'team_members' ] },
					before( { trigger } ) {
						if ( trigger.classList.contains( 'listing' ) ) {
							trigger.style.zIndex = 3;
						} else {
							barba.force( trigger.href );
							runMainFunctions();
							barba.destroy();
						}
					},
					leave: ( { trigger } ) => Promise.all( [
						expandImage( trigger, duration, trigger.offsetWidth, '100vw', '0px', ( trigger.getBoundingClientRect().top * -1 ), '0px', ( trigger.getBoundingClientRect().left * -1 ) ),
					] ),
					after: ( { next } ) => Promise.all( [
						window.scrollTo( { top: 0, behavior: 'instant' } ),
						fadeOutImage( next.container.querySelector( '.intro__image' ), next.container.querySelector( '.intro' ), intro_fade_duration ),
						runMainFunctions(),
					] ),
				},
				{
					name: 'default-transition',
					from: { namespace: [ 'projects', 'approaches',  'core_teams', 'team_members','homepage', 'portfolio', 'approach', 'about_us', 'team', 'contact', 'buy_from_us' ] },
					to: { namespace: [ 'homepage', 'portfolio', 'approach', 'about_us', 'team', 'contact', 'buy_from_us' ] },
					before( data ) {
						barba.force( data.next.url.href );
						runMainFunctions();
						barba.destroy();
					},
					leave() {},
				},
			]
		} );
	}
}
