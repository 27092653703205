import anime from 'animejs/lib/anime.es.js';

export default ( targets, duration, width_from, width_to, top_from, top_to, left_from, left_to ) => {
	if ( !targets.classList.contains( 'listing' ) ) {
		return;
	}
	targets.style.width = `${width_from}`;
	targets.style.top = `${top_from}`;
	targets.style.left = `${left_from}`;

	const text = targets.querySelector( '.listing__content' );
	text.style.opacity = 0;
	text.style.transition = 'opacity 1.2s ease';

	const anim = anime.timeline( {
		easing: 'easeInOutSine',
		duration: duration,
	} );

	anim.add( {
		targets: targets,
		width: width_to,
		top: top_to,
		left: left_to
	} );

	return anim.finished;
};
