import anime from 'animejs/lib/anime.es.js';

export default ( image, intro, duration ) => {
	const from = ( image.getBoundingClientRect().top * -1 );
	intro.classList.add( 'intro--shape-hidden' );
	image.style.opacity = '0.8';
	image.style.position = 'relative';
	image.style.transform = `translateY( ${from}px )`;

	const anim = anime.timeline( {
		easing: 'easeInOutSine',
		duration: duration,
	} );

	setTimeout( () => {
		image.style.opacity = '1';
		image.style.position = 'static';
		intro.classList.remove( 'intro--shape-hidden' );
	}, 580 );

	anim.add( {
		targets: image,
		translateY: 0,
	} );

	return anim.finished;
};