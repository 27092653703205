export const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
    prevBtn.addEventListener('click', embla.scrollPrev, false);
    nextBtn.addEventListener('click', embla.scrollNext, false);
  };
  
  export const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
    return () => {
      if (embla.canScrollPrev()) {
        prevBtn.removeAttribute('disabled');
        if ( prevBtn.closest('.embla') ) {
          prevBtn.classList.add('embla__button--active'); 
        }
      }
      else {
        prevBtn.setAttribute('disabled', 'disabled');
        if ( prevBtn.closest('.embla') ) {
          prevBtn.classList.remove('embla__button--active'); 
        }
      }
  
      if (embla.canScrollNext()) {
        nextBtn.removeAttribute('disabled');
        if ( nextBtn.closest('.embla') ) {
          nextBtn.classList.add('embla__button--active');
        }
      }
      else {
        nextBtn.setAttribute('disabled', 'disabled');
        if ( nextBtn.closest('.embla') ) {
          nextBtn.classList.remove('embla__button--active');
        }
      }


      if( ( !embla.canScrollNext() && !embla.canScrollPrev() ) ) {
        nextBtn.style.display = 'none';
        prevBtn.style.display = 'none';
      } else {
        nextBtn.style.display = 'block';
        prevBtn.style.display = 'block';
      }

    };
};

